import Spaceship        from 'foundations/layout/spaceship.jpg';
import {Button}         from 'foundations/button/button';
import {useTranslation} from 'foundations/i18n/i18n';

export function EapSignupCta() {
    const {t} = useTranslation();
    return (
        <div className={'site-width'}>
            <div className={'py-12 px-8 rounded-xl bg-bottom bg-cover object-center'} style={{
                backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8) ), url(${Spaceship.src})`
            }}>
                <div className={'flex flex-col gap-4 md:flex-row justify-between items-center'}>
                    <div>
                        <h1 className={'font-bold leading-tight text-3xl lg:text-5xl mb-2 text-white'}>{t('Supercharge your engineering team')}</h1>
                        <span className={'block text-xl text-white'}>{t('Start your 30-day trial, no credit card required')}</span>
                    </div>
                    <div>
                        <Button href={'https://app.withglimpse.com'}>{t('Start trial')}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}